import { Controller } from "stimulus"

export default class extends Controller {
  static get targets() {
    return ['searchbarContainer'];
  };

  toggleFilters() {
    this.searchbarContainerTarget.classList.toggle('onclick-show-filters');
  };

  toggleFiltersFromShow() {
    console.log("pressed")
    document.getElementById("is-filters").classList.add('onclick-show-filters');
  }

  scrollForFilters() {

  // console.log(window.scrollY)
    if (window.oldScroll > window.scrollY) {
      this.searchbarContainerTarget.classList.remove('onclick-show-filters');
      if (window.scrollY > 150) {
          this.searchbarContainerTarget.classList.add('transition-ease-super-slow');
          this.searchbarContainerTarget.classList.remove('javascript-stick-to-top');
          this.searchbarContainerTarget.classList.add('javascript-stick-to-secondary');
          this.searchbarContainerTarget.classList.add('javascript-set-z-index');
      } else {
        // scrolling down less than 484
        this.searchbarContainerTarget.classList.remove('javascript-stick-to-top');
        this.searchbarContainerTarget.classList.remove('javascript-stick-to-secondary');
      }
    } else if (window.oldScroll < window.scrollY) {
      if (window.scrollY > 484) {
         // scrolling up greater than
          this.searchbarContainerTarget.classList.remove('transition-ease-super-slow');
          this.searchbarContainerTarget.classList.remove('javascript-stick-to-secondary');
          this.searchbarContainerTarget.classList.add('javascript-stick-to-top');
          this.searchbarContainerTarget.classList.add('javascript-set-z-index');
       } else {
         // scrolling up less than 484
          this.searchbarContainerTarget.classList.remove('javascript-stick-to-top');
          this.searchbarContainerTarget.classList.remove('javascript-stick-to-secondary');
       }
    }
  }
}

window.onclick = function(event) {
  if (event.target.classList[0] == 'autocomplete-suggestion'){
    document.getElementById('search-button-index-page').click();
  }
}




