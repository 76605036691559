import { Controller } from "stimulus"

export default class extends Controller {
  static get targets() {
    return ['langDropdown', 'langSelector', 'secondaryNavbar', 'sideMenu', 'menuButton'];
  };

  toggleDropdown(event) {
    this.langDropdownTarget.classList.toggle('is-hidden')
  };

  changeToEnglish() {
    document.cookie = "locale=en";
    location.reload();
  };

  changeToSpanish() {
    document.cookie = "locale=es";
    location.reload();
  };

  changeToGerman() {
  document.cookie = "locale=de";
  location.reload();
  };

  scrollNavResize(event) {

    let secondaryNavbar = this.secondaryNavbarTarget
    window.onscroll = function() {
      // Lets check if last scroll is greater or lesser than the scroll from before
      if (this.oldScroll > this.scrollY) {
        // Scrolling up - Expand Navbar
        secondaryNavbar.classList.remove('js-retract-navbar')
        secondaryNavbar.classList.add('js-expand-navbar')
      } else if (this.oldScroll < this.scrollY) {
        // Scrolling down - Expand Navbar
        secondaryNavbar.classList.remove('js-expand-navbar')
        secondaryNavbar.classList.add('js-retract-navbar')
      } else {
        // Not Scrolling. Stay how you are.
      }
      // Set oldScroll to wherever we just scrolled
      this.oldScroll = this.scrollY;
    }
  }

  showSideMenu(event) {
    let sideMenu = this.sideMenuTarget;
    let menuButton = this.menuButtonTarget;
    sideMenu.classList.toggle("width-zero");
    menuButton.classList.toggle("pressed");
  }
}





