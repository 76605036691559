import { Controller } from "stimulus"

export default class extends Controller {
  static get targets() {
    return [];
  };

  hideFlashAlert(event) {
    this.element.classList.add('is-hidden');
  }

}





