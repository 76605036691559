import { Controller } from "stimulus"

export default class extends Controller {
  static get targets() {
    return ['restOfPictures', 'morePicturesUpperButton', 'morePicturesLowerButton'];
  };

  showAllPicturesUpperButton(event) {
    console.log( this.morePicturesUpperButtonTarget );
    document.getElementById("lowerbutton").classList.add('is-hidden');
    document.getElementById("restofpicturesonshowpage").classList.remove('is-hidden');
  }

  showAllPicturesLowerButton(event) {
    console.log( this.morePicturesLowerButtonTarget );
    this.morePicturesLowerButtonTarget.classList.add('is-hidden')
    console.log("...........")
    console.log( this.restOfPicturesTarget );
    this.restOfPicturesTarget.classList.remove('is-hidden')
  }
}





